"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import React from "react"

import Image from "../parts/image"
import { Spacer } from "../parts/styled"
import { Title } from "../parts/Title"
import { RandNum } from "../parts/utils"

// eslint-disable-next-line react/prop-types
const ContentGrid = ({ title, subtitle, values }) => (
  <>
    {/*###################################*/}
    <Title text={title} />

    <Spacer v={3} />

    {/*###################################*/}
    <Typography component={`p`} variant={`subtitle1`}>
      <b>{subtitle}</b>
    </Typography>

    <Spacer v={3} />

    {/*###################################*/}
    <Grid container spacing={3}>
      {values.map((c) => (
        <Grid item sm={6} key={RandNum()}>
          <Image filename={c.filename} alt={c.filename} width={`1024px`} />

          <Typography component={`p`} variant={`caption`} align={`center`}>
            〈 {c.caption} 〉
          </Typography>

          <Spacer v={3} />

          <Typography component={`p`} variant={`body2`}>
            {c.text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </>
)

ContentGrid.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string,
  values: PropTypes.array,
}
ContentGrid.defaultProps = {
  title: ``,
  filename: ``,
}

export default ContentGrid
