"use strict"

import Grid from "@material-ui/core/Grid"
import Carousel from "nuka-carousel"
import React from "react"

import Image from "../parts/image"
import { Spacer } from "../parts/styled"
import { Title } from "../parts/Title"
import { RandNum } from "../parts/utils"

const ImageGrid = () => (
  <>
    <Title text={`施工事例`} />

    <Spacer v={3} />

    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Carousel>
          {[0, 1, 2, 3].map((i) => (
            <Image
              filename={`index/grid/${i}.jpg`}
              alt={`${i}`}
              width={`1280px`}
              key={RandNum()}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  </>
)

export default ImageGrid
