"use strict"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import UpdateIcon from "@material-ui/icons/Update"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { RandNum } from "../parts/utils"

/**
 * @property {object} allWhatsnewJson
 */

// ###################################
const WhatsNew = () => {
    const items = useStaticQuery(graphql`
    query WhatsNesQuery {
      allWhatsnewJson {
        edges {
          node {
            date
            text
          }
        }
      }
    }
  `)

    return (
        <List >
            {items.allWhatsnewJson.edges.map((i) => (
                <ListItem key={RandNum()}>
                    <ListItemIcon>
                        <UpdateIcon />
                    </ListItemIcon>

                    <ListItemText primary={i.node.text} secondary={i.node.date} />
                </ListItem>
            ))}
        </List>
    )
}

export default WhatsNew
