"use strict"

import "date-fns"

import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import { Spacer } from "../parts/styled"

// ###################################
const Wrapper = styled.div`
  padding: 0 ${theme.spacing(2)}px;

  & * {
    font-family: "Noto Sans JP", "Lato", "Roboto", sans-serif;
  }

  label,
  input,
  textarea {
    text-align: left;
  }
`

// ###################################
// eslint-disable-next-line react/prop-types
const TxtField = ({ type, id, name, label, required }) => (
  <>
    <TextField
      type={type}
      id={id}
      name={name}
      label={
        <>
          {label}{" "}
          {required === `` ? (
            ``
          ) : (
            <span style={{ color: `red` }}>（必須）</span>
          )}{" "}
        </>
      }
      color={`secondary`}
      margin={`normal`}
      required={required}
    />

    <Spacer v={1} />
  </>
)

// ###################################
const Inquiry = () => (
  <>
    {/*###################################*/}
    <Typography variant={`h5`} component={`h5`} align={`center`}>
      <b>＞ 電話で問合せする</b>
    </Typography>

    <Spacer />

    {/*###################################*/}
    <Typography variant={`h2`} component={`h2`} align={`center`}>
      055-233-2813
    </Typography>

    <Spacer v={11} />

    {/*###################################*/}
    <Typography variant={`h5`} component={`h5`} align={`center`}>
      <b>＞ メールで問合せする</b>
    </Typography>

    {/*###################################*/}
    <Wrapper>
      <form
        action={`https://formspree.io/info-yg@y-1.co.jp`}
        acceptCharset={`utf-8`}
        method={`post`}
      >
        <FormControl component={`fieldset`} fullWidth={true} margin={`normal`}>
          {/*###################################*/}
          <TxtField
            type={`text`}
            id={`name`}
            name={`氏名`}
            label={`お名前`}
            required={true}
          />

          {/*###################################*/}
          <TxtField
            type={`text`}
            id={`ruby`}
            name={`ふりがな`}
            label={`ふりがな`}
            required={true}
          />

          {/*###################################*/}
          <TxtField
            type={`email`}
            id={`email`}
            name={`_replyto`}
            label={`メールアドレス`}
            required={true}
          />

          {/*###################################*/}
          <TxtField
            type={`tel`}
            id={`tel`}
            name={`電話番号`}
            label={`お電話番号`}
            required={false}
          />

          {/*###################################*/}
          <TextField
            id=" memo"
            name={`お問い合わせ内容`}
            label=" お問い合わせ内容"
            color={`secondary`}
            margin={`normal`}
            multiline
            rows="5"
          />
        </FormControl>

        <Spacer />

        {/*###################################*/}
        <input
          type="hidden"
          name="_subject"
          id="email-subject"
          value="y-1.co.jp からの問い合わせ"
        />

        <div style={{ textAlign: `center` }}>
          <Button
            variant={`contained`}
            type={`submit`}
            value={`メールを送信する`}
          >
            メールを送信する
          </Button>
        </div>
      </form>
    </Wrapper>
  </>
)

export default Inquiry
