"use strict"

import React, { useRef, useState } from "react"

// TODO: https://blog.daisukekonishi.com/post/react-listen-scroll/
// TODO: https://hiroshi-yokota.com/2019/10/23/fadein/

const FadeInSection = (props) => {
  const ref = useRef()
  const [isVisible, setVisible] = useState(false)

  React.useEffect(() => {
    const funcY = () => {
      let innerHeight = window.innerHeight
      // showTiming = innerHeight > 768 ? 200 : 80
      let scrollY = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )
      let rect = ref.current.getBoundingClientRect()

      let elmY = scrollY + rect.top
      let timing = innerHeight + scrollY - (rect.bottom - rect.top) * 0.1

      if (timing > elmY) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }

    window.addEventListener(`scroll`, () => funcY(), true)

    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     setVisible(entry.isIntersecting)
    //   })
    // }, {})
    //
    // observer.observe(ref.current)

    return () => window.removeEventListener(`scroll`, () => {})
  }, [isVisible])

  return (
    <div
      ref={ref}
      className={`fade-in-section
        ${props.i % 2 ? `d-r` : `d-l`}
        ${isVisible ? "is-visible" : ""}`}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </div>
  )
}

export default FadeInSection
