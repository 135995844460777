"use strict"

import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import Image from "./image"

// ###################################
const TypographyTitle = styled(Typography)`
  border-bottom: 1px solid silver;
  letter-spacing: 0.8rem;
  padding-bottom: ${theme.spacing(1)}px;
`

// ###################################
const TypographySubTitle = styled(Typography)`
  margin-left: ${(props) => theme.spacing(props.v)}px;
`
TypographySubTitle.propTypes = {
  v: PropTypes.number,
}
TypographySubTitle.defaultProps = {
  v: 0,
}

// ###################################
const Wrapper = styled.div`
  display: inline-block;
  margin-right: ${theme.spacing(3)}px;
  width: 34px;
  height: 40px;
  vertical-align: middle;
`

// ###################################
export const Title = ({ text }) => (
  <TypographyTitle component={`h1`} variant={`body1`}>
    <Wrapper>
      <Image filename={`logo-mini.png`} alt={`icon`} width={`34px`} />
    </Wrapper>

    <b>{text}</b>
  </TypographyTitle>
)

Title.propTypes = {
  text: PropTypes.string,
}
Title.defaultProps = {
  text: ``,
}

// ###################################
export const SubTitle = ({ children, v }) => (
  <TypographySubTitle component={`h2`} variant={`body1`} v={v}>
    <b>{children}</b>
  </TypographySubTitle>
)

SubTitle.propTypes = {
  children: PropTypes.node,
  v: PropTypes.number,
}
SubTitle.defaultProps = {
  children: ``,
}
