"use strict"

import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import LinkIcon from "@material-ui/icons/Link"
import UpdateIcon from "@material-ui/icons/Update"
import React from "react"

import Content from "../components/index/content"
import ContentGrid from "../components/index/contentGrid"
import Gmap from "../components/index/gmap"
import ImageGrid from "../components/index/imageGrid"
import Inquiry from "../components/index/inquiry"
import MyTable from "../components/index/myTable"
import WhatsNew from "../components/index/whatsnew"
import Layout from "../components/layout"
import FadeInSection from "../components/parts/fadein"
import Image from "../components/parts/image"
import SEO from "../components/parts/seo"
import { Spacer } from "../components/parts/styled"
import { Title } from "../components/parts/Title"
import { RandNum } from "../components/parts/utils"
import theme from "../theme"

// ###################################
const IndexPage = () => (
    <Layout>
        <SEO title={``} />

        <Grid container spacing={3}>
            {/*###################################*/}
            <Grid item xs={12}>
                {/*###################################*/}
                <Title text={`更新情報`} />

                <Spacer v={1} />

                <WhatsNew />

                <List >
                    <ListItem key={RandNum()}>
                        <ListItemIcon>
                            <UpdateIcon />
                        </ListItemIcon>

                        <ListItemText
                            primary={
                                <a href="https://boy-k1st.ssl-lolipop.jp/y-1/">
                                    <LinkIcon style={{ marginRight: theme.prime.sp011 }} />
                                    CAD はこちらから
                                </a>
                            }
                            secondary={
                                <span style={{ color: `red` }}>
                                    （注意）CAD
                                    データの閲覧情報（ユーザー名・パスワード）は本社へのお電話（TEL
                                    055-233-2813）にてご確認下さい。
                                </span>
                            }
                        />
                    </ListItem>
                </List>

                <Spacer v={11} />

                {/*###################################*/}
                <FadeInSection i={0}>
                    <Title text={`山一窯業株式会社とは？`} />

                    <Spacer v={3} />

                    <Image
                        filename={`1-1-cut.jpg`}
                        alt={`旧工場前風景`}
                        width={`1024px`}
                    />

                    <Typography component={`p`} variant={`caption`} align={`center`}>
                        〈 旧工場前 風景 〉
                    </Typography>

                    <Spacer />

                    <Typography
                        component={`p`}
                        variant={`body2`}
                        style={{ width: `88.4%`, margin: `0 auto` }}
                    >
                        私たちは、日本産業規格製品認証取得工場（JIS
                        規格認証工場）を取得した、創業から
                        150年を迎えるコンクリート二次製品メーカーです。
                        <br />
                        <u>一般道路製品</u> を始め、<u>大型ブロック擁壁</u>・<u>円型水路</u>{" "}
                        の <span style={{ color: `red` }}>フロンティア</span> であり、
                        <u>オリジナル製品</u> や <u>オーダーメイド製品</u>{" "}
                        など、市場ニーズにも応え続ける{" "}
                        <span style={{ color: `red` }}>フレキシブルカンパニー</span> です。
                    </Typography>
                </FadeInSection>

                <Spacer v={11} />

                {/*###################################*/}
                <FadeInSection i={1}>
                    <Content
                        title={`製品紹介`}
                        subtitle={
                            "【 T 型 ブ ロ ッ ク 】　〜 災害時にも能力を発揮する認定製品 〜"
                        }
                        filename={`index/0-0.jpg`}
                        caption={`富士銘水`}
                        text={
                            <>
                                「T型ブロック」は、国土交通大臣認定（Wタイプ）建設技術審査証明書（ティーロード）を取得した製品の総称で、災害時にも能力を発揮するブロック積擁壁です。
                                一般的な「建知ブロック」や「現場打ち擁壁」は作業が極めて煩雑ですが、「T型ブロック」による工法は極めて省力化されるため熟練工を必要とせず工期の短縮が可能です。
                                また、垂直に施工する「T型ブロック」は傾斜地において数量を著しく減少できるため、所要経費も大幅に節約できると共に、用地の有効活用にも寄与します。
                                <br />
                                <br />
                                山一窯業株式会社は、「T型ブロック」を通して、日本の安全に貢献しています。
                                <br />
                                <br />
                                詳しくは <a href="/ttype/">こちら</a>
                            </>
                        }
                    />

                    <Spacer v={3} />

                    {/*###################################*/}
                    <Content
                        title={``}
                        subtitle={
                            "【 円 型 水 路 】　〜 堆積を抑え、排水能力に優れたオリジナル製品 〜"
                        }
                        filename={`index/1-0.jpg`}
                        caption={`東京国際空港`}
                        text={
                            <>
                                円型水路はスパイラルな水の流れにより堆積物を抑えて高い排水能力を発揮します。
                                また、排水取口が狭いため、車両の交通による振動や騒音が無く、快適な道路環境を実現できます。
                                幅の狭い道路にも充分適応すると共に優れた施工性によって、あらゆる道路環境にも広く利用可能です。
                                快適な道路環境の整備は安全・安心な交通環境に役立ちます。
                                <br />
                                <br />
                                山一窯業株式会社は、様々な「円型水路」の製品群を備えた{" "}
                                <span style={{ color: `red` }}>
                                    リーディングカンパニー
                                </span>{" "}
                                です。
                            </>
                        }
                    />
                </FadeInSection>

                <Spacer v={11} />

                {/*###################################*/}
                <FadeInSection i={0}>
                    <ImageGrid />
                </FadeInSection>

                <Spacer v={11} />

                {/*###################################*/}
                <FadeInSection i={1}>
                    <ContentGrid
                        title={`業務紹介`}
                        subtitle={`一般製品以外の業務を紹介いたします。`}
                        values={[
                            {
                                filename: `index/2-0.jpg`,
                                caption: `宮ヶ瀬副ダム`,
                                text: `近年、小規模ダムおよび砂防ダムにおいて工事の省力化・工期の短縮・作業の安全性および産業廃棄物の発生の軽減を目的に、堤体および監査通廊のプレキャスト化が試工されてきました。弊社においても神奈川県宮ヶ瀬副ダム（石小屋ダム）における上下流の壁面をプレキャストした堤体ブロックと監査通廊のプレキャストブロックを建設省関東地方建設局宮ヶ瀬ダム工事事務所の指導により製作・納入をいたしました。`,
                            },

                            {
                                filename: `index/2-1.jpg`,
                                caption: `永平寺川ダム`,
                                text: `また、福井県発注の永平寺ダムにおいては堤体の列柱部・洪水吐部・橋台部にパネルブロックを用いることにより大幅な工期縮減と産業廃棄物の軽減が行われました。何れの工事においても高い評価を得ております。弊社と致しましては今後とも小規模ダムおよび砂防ダムにおける経費縮減のためのプレキャスト化に取り組み、官公庁・設計コンサルタント・ゼネコンの皆様に提言して参ります。`,
                            },
                        ]}
                    />
                </FadeInSection>

                <Spacer v={10} id={`profile`} />

                {/*###################################*/}
                <FadeInSection i={0}>
                    <Title text={`会社概要`} />

                    <Spacer v={3} />

                    <MyTable
                        row={[
                            { th: "【 法人名 】", td: ["山一窯業株式会社", ""] },
                            {
                                th: "【 事業内容 】",
                                td: ["コンクリート二次製品製造販売", ""],
                            },
                            { th: "【 代表取締役 】", td: ["楳 窪 一 浩", ""] },
                            { th: "【 会社設立 】", td: ["昭和 24年", ""] },
                            { th: "【 会社沿革 】", td: ["", ""] },
                            {
                                th: "明治05年",
                                td: ["土器瀬戸物製造販売店「うめや」として創業", ""],
                            },
                            { th: "昭和10年", td: ["土器赤煉瓦､鉢類を製造開始", ""] },
                            { th: "昭和24年", td: ["楳窪製陶株式会社に改組", ""] },
                            { th: "昭和26年", td: ["山一窯業株式会社に社名変更", ""] },
                            { th: "昭和46年", td: ["コンクリート 二次製品製造着手", ""] },
                            {
                                th: "昭和53年",
                                td: ["コンクリート 二次製品道路製品 JIS取得", ""],
                            },
                            { th: "昭和56年", td: ["下水道製品製造開始", ""] },
                            {
                                th: "昭和57年",
                                td: ["コンクリート 二次製品下水道製品 JIS取得", ""],
                            },
                            { th: "昭和62年", td: ["一宮工場 新設", ""] },
                            {
                                th: "平成02年",
                                td: [
                                    "一宮工場 コンクリート 二次製品道路製品 JIS取得",
                                    "許可番号 378154",
                                ],
                            },
                            {
                                th: "平成05年",
                                td: [
                                    "Ｔ型ブロック擁壁 建設大臣認定取得",
                                    "建設省梨経民発第１号（現 国都防第 6号）",
                                ],
                            },
                            {
                                th: "平成09年",
                                td: [
                                    "Ｔ型ブロック擁壁 Wタイプ擁壁 建設大臣認定取得",
                                    "建設省梨経民発第１号（現 国都防第 25号）",
                                ],
                            },
                            {
                                th: "平成12年",
                                td: [
                                    "ティー・ロード擁壁 技術審査証明取得",
                                    "（現 建技審証第 0520号）",
                                ],
                            },
                            { th: "平成17年", td: ["東京支店 開設", ""] },
                            {
                                th: "平成19年",
                                td: [
                                    "一宮工場 JIS製品認証取得（TC0307301）",
                                    "JIS A 5371・JIS A 5372",
                                ],
                            },
                        ]}
                    />
                </FadeInSection>

                <Spacer id={`map`} />

                {/*###################################*/}
                <FadeInSection i={1}>
                    <Gmap />
                </FadeInSection>

                <Spacer v={10} id={`inquiry`} />

                {/*###################################*/}
                <FadeInSection i={0}>
                    <Title text={`お問い合わせ`} />

                    <Spacer />

                    <Inquiry />
                </FadeInSection>

                <Spacer v={11} />
            </Grid>
        </Grid>
    </Layout>
)

export default IndexPage
