"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import PhoneIcon from "@material-ui/icons/Phone"
import PrintIcon from "@material-ui/icons/Print"
import React from "react"

import theme from "../../theme"
import { Spacer } from "../parts/styled"
import { RandNum } from "../parts/utils"

const data = [
  {
    name: "本社",
    post: "400-0861",
    address: "山梨県甲府市城東5-5-18",
    tel: "055-233-2813 (代表)",
    fax: "055-233-2837",
    gmap:
      "!1m18!1m12!1m3!1d810.4331524278946!2d138.58760282930427!3d35.65895899876246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601bf99791d43721%3A0x19ba1564c9dd76c3!2z5bGx5LiA56qv5qWt77yI5qCq77yJIOWWtualremDqA!5e0!3m2!1sja!2sjp!4v1557890847570!5m2!1sja!2sjp",
  },

  {
    name: "一宮工場",
    post: "405-0074",
    address: "山梨県笛吹市一宮町国分1146",
    tel: "0553-47-1369",
    fax: "0553-47-1957",
    gmap:
      "!1m14!1m8!1m3!1d405.31199999744564!2d138.6771803!3d35.6401495!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601bfcfe41867827%3A0x32564f292a3f56b0!2z5bGx5LiA56qv5qWt44ixIOS4gOWuruW3peWgtA!5e0!3m2!1sja!2sjp!4v1557883661693!5m2!1sja!2sjp",
  },

  {
    name: "東京支店",
    post: "160-0022",
    address: "東京都新宿区新宿5-1-19-701",
    tel: "03-5919-2320",
    fax: "03-5919-2327",
    gmap:
      "!1m18!1m12!1m3!1d3240.3677804593462!2d139.71041611525914!3d35.69256618019173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ce7e63acfa3%3A0x986948c3e656705d!2z44CSMTYwLTAwMjIg5p2x5Lqs6YO95paw5a6_5Yy65paw5a6_77yV5LiB55uu77yR4oiS77yR77yZ!5e0!3m2!1sja!2sjp!4v1557891419651!5m2!1sja!2sjp",
  },
]

// ###################################
const Gmap = () => (
  <>
    <Grid container spacing={1}>
      {data.map((c) => (
        <Grid item lg={3} md={6} sm={12} xs={12} key={RandNum()}>
          <Typography component={`p`} variant={`subtitle1`}>
            <b>{c.name}</b>
          </Typography>

          <Typography component={`p`} variant={`subtitle2`}>
            〒{c.post} {c.address}
            <br />
            <PhoneIcon
              style={{ fontSize: 15, marginRight: theme.prime.sp007 }}
            />
            {c.tel}
            <br />
            <PrintIcon
              style={{ fontSize: 15, marginRight: theme.prime.sp007 }}
            />
            {c.fax}
            <br />
          </Typography>

          <Spacer v={1} />

          <iframe
            src={`https://www.google.com/maps/embed?pb=${c.gmap}`}
            width={`100%`}
            frameBorder={`0`}
            style={{ border: 0 }}
            allowFullScreen={``}
            aria-hidden={`false`}
            tabIndex={`0`}
          />
        </Grid>
      ))}
    </Grid>
  </>
)

export default Gmap
