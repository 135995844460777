"use strict"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import PropTypes from "prop-types"
import React from "react"

import theme from "../../theme"
import { RandNum } from "../parts/utils"

// ###################################
const MyTable = ({ row }) => (
  // https://material-ui.com/api/table-container/
  <TableContainer>
    {/*https://material-ui.com/api/table/*/}
    <Table size={`small`}>
      <TableBody>
        {row.map((c) => (
          // https://material-ui.com/api/table-row/
          <TableRow hover={true} key={RandNum()}>
            {/*https://material-ui.com/api/table-cell/*/}
            <TableCell
              component={`th`}
              align={`right`}
              style={{ width: `${theme.prime.sp017}%`, whiteSpace: `nowrap` }}
            >
              <b>{c.th}</b>
            </TableCell>

            <TableCell component={`td`}>
              {c.td[0]} <br /> {c.td[1]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

MyTable.propTypes = {
  row: PropTypes.array.isRequired,
}

export default MyTable
