"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import React from "react"

import Image from "../parts/image"
import { Spacer } from "../parts/styled"
import { Title } from "../parts/Title"

const Content = ({ title, filename, caption, subtitle, text }) => (
  <>
    {/*###################################*/}
    {title ? <Title text={title} /> : ``}

    <Spacer v={3} />

    {/*###################################*/}
    <Typography component={`p`} variant={`subtitle1`}>
      <b>{subtitle}</b>
    </Typography>

    <Spacer v={3} />

    {/*###################################*/}
    <Grid container spacing={3}>
      <Grid item md={5} sm={12} xs={12}>
        <Image filename={filename} alt={filename} width={`1024px`} />

        <Typography component={`p`} variant={`caption`} align={`center`}>
          〈 {caption} 〉
        </Typography>
      </Grid>

      <Grid item md={7} sm={12} xs={12}>
        <Typography component={`p`} variant={`body2`}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  </>
)

Content.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string,
  caption: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.object,
}
Content.defaultProps = {
  title: ``,
  filename: ``,
  caption: ``,
  subtitle: ``,
}

export default Content
